import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SwPush, SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { environment } from '@scbt-env/environment';
import {
	IWorkerMsg,
	SW_ACTIVATED_VERSION,
	SW_INSTALLED_VERSION,
	UID,
	WORKER_MSG_TYPE,
	readCache,
	storageGet,
	writeCache,
} from '@scbt-lib/index';

import { Firestore, deleteDoc, doc, setDoc } from '@angular/fire/firestore';
import { Messaging, Unsubscribe, getToken, isSupported, onMessage } from '@angular/fire/messaging';
import { FirebaseError } from 'firebase/app';
import { BehaviorSubject, Observable, of, throwError, zip } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';

export type TypeNotificationPermission = NotificationPermission | 'unsupported';

@Injectable({ providedIn: 'root' })
export class SWService {
	private swReg: ServiceWorkerRegistration;

	private swUpdateAvailableSbj$: BehaviorSubject<VersionReadyEvent> = new BehaviorSubject(null);
	get swUpdateAvailable$() {
		return this.swUpdateAvailableSbj$.asObservable();
	}
	private swInstalledVersionSbj$ = new BehaviorSubject('N/A');
	get swInstalledVersion$() {
		return this.swInstalledVersionSbj$.asObservable();
	}
	private swActivatedVersionSbj$ = new BehaviorSubject('N/A');
	get swActivatedVersion$() {
		return this.swActivatedVersionSbj$.asObservable();
	}

	private currentMessage = new BehaviorSubject(null);

	private currentToken: string;
	private monitorTokenRefreshSub: Unsubscribe;
	private receiveMessagesSub: Unsubscribe;

	constructor(
		private afFs: Firestore,
		private fbMessaging: Messaging,
		private authSrv: AuthService,
		private swPush: SwPush,
		private swUpdate: SwUpdate,
		private router: Router
	) {
		this.setCurrentUidToCache();

		this.startSwRegistration();
	}

	async startSwRegistration() {
		if ('serviceWorker' in navigator && environment.production) {
			this.readInstalledVersion();
			this.readActivatedVersion();

			console.log('[CLIENT] attempting to register SW');
			// this.swReg = await navigator.serviceWorker.register('/sw-combo.js');
			this.swReg = await navigator.serviceWorker.register('/ngsw-worker.js');
			console.log('[CLIENT] SW ready');

			this.swUpdate.versionUpdates
				.pipe(
					tap((v) => console.log(v)),
					filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY')
				)
				.subscribe((event) => {
					console.log({ event });
					this.swUpdateAvailableSbj$.next(event);
				});

			// this.fbMessaging.useServiceWorker(this.swReg);

			navigator.serviceWorker.addEventListener('message', this.handleMsgsFromSW, false);
			this.swPush.messages.subscribe((event: unknown) => {
				console.log('swPush message:', event);
			});
		}
	}

	setCurrentUidToCache() {
		this.authSrv.user$.subscribe((user) => {
			const uid = user?.uid ?? null;
			writeCache(UID, uid);
		});
	}

	async readInstalledVersion() {
		const ver = await readCache(SW_INSTALLED_VERSION).catch((err) => console.log("[CLIENT] can't read SW Installed version", err));
		if (ver) {
			console.log('[CLIENT] SW Installed version', ver);
			this.swInstalledVersionSbj$.next(ver);
		}
		return ver;
	}
	async readActivatedVersion() {
		const ver = await readCache(SW_ACTIVATED_VERSION).catch((err) => console.log("[CLIENT] can't read SW Activated version", err));
		if (ver) {
			console.log('[CLIENT] SW Activated version', ver);
			this.swActivatedVersionSbj$.next(ver);
		}
		return ver;
	}

	// ************************************** Sw inter Comms ****************************

	// eslint-disable-next-line @typescript-eslint/member-ordering
	static async sendMsgToWorker(msg: IWorkerMsg) {
		if ('serviceWorker' in navigator) {
			await navigator.serviceWorker.ready;
			// on first install, SW may not be yet activated thus controller is null
			if (navigator.serviceWorker.controller) {
				navigator.serviceWorker.controller.postMessage(msg);
			}
		}
	}

	handleMsgsFromSW = (event: MessageEvent) => {
		console.log('[CLIENT] message from worker', event);

		// do not use switch, doesn't work for me!!
		if (event.data.type === WORKER_MSG_TYPE.SW_INSTALLED) {
			this.swInstalledVersionSbj$.next(event.data.str);
		} else if (event.data.type === WORKER_MSG_TYPE.SW_ACTIVATED) {
			this.swActivatedVersionSbj$.next(event.data.str);
		} else if (event.data.type === WORKER_MSG_TYPE.LOG) {
			const msg: IWorkerMsg = event.data.obj;
			console.log('[CLIENT] LOG: ' + msg.str, msg.obj);
		} else if (event.data.type === 'NOTIFICATION_CLICK') {
			// event from pure SW
			const appData = event.data.data.notification.data;
			console.log('[CLIENT] Notification clicked', { appData });
		} else if (event.data.type === WORKER_MSG_TYPE.NOTIFICATION_DISPATCHED) {
			const msg: IWorkerMsg = event.data.obj;
			console.log('[CLIENT] Notification dispatched', { appData: msg.obj });
		} else if (event.data.type === WORKER_MSG_TYPE.NOTIFICATION_CLOSED) {
			const msg: IWorkerMsg = event.data;
			console.log('[CLIENT] Notification closed', { appData: msg.obj });
		} else if (event.data.type === WORKER_MSG_TYPE.NAVIGATE) {
			const msg: IWorkerMsg = event.data;
			console.log('[CLIENT] Navigate', { appData: msg });
			const { path, queryParams, extras } = msg.obj.routerNavigation;
			if (path) {
				this.router.navigate(path, { queryParams, ...extras });
			}
		}
	};

	// ***************************************** Push Notification *******************************
	async getPushNotificationAvailable() {
		return this.getPushNotificationSupported() && (await navigator.serviceWorker.ready);
	}

	getPushNotificationSupported(): Promise<boolean> {
		return isSupported();
		// return 'serviceWorker' in navigator && 'showNotification' in ServiceWorkerRegistration.prototype && 'PushManager' in window;
	}

	getPushNotificationPermission(): TypeNotificationPermission {
		return this.getPushNotificationSupported() ? Notification.permission : 'unsupported';
	}

	async shouldOpenNotificationDialog(): Promise<boolean> {
		const permission = this.getPushNotificationPermission();
		const userDecision = storageGet('dontAskForNotificationsAnymore');
		const res = permission !== 'granted' && !userDecision;
		let reg: ServiceWorkerRegistration;
		if (res) {
			reg = await navigator.serviceWorker.getRegistration();
		}
		return res && reg != null;
	}

	async subscribeToPushMessages(): Promise<void> {
		const reg = await navigator.serviceWorker.ready;
		if (reg) {
			return this.getPermission().then(() => {
				// this.monitorTokenRefresh();
				this.receiveMessages();
			});
		}
	}

	async subscribeToPushMessages_IfPermissionGranted(): Promise<void> {
		if (this.getPushNotificationPermission() === 'granted') {
			this.subscribeToPushMessages();
		}
	}

	async unsubscribeToPushMessages(): Promise<void> {
		this.fbMessaging;
		if (this.monitorTokenRefreshSub) {
			this.monitorTokenRefreshSub();
		}
		if (this.receiveMessagesSub) {
			this.receiveMessagesSub();
		}
		const token = await getToken(this.fbMessaging);
		if (token) {
			return this.deleteToken(token).toPromise();
		}
	}

	// get permission to send messages
	private async getPermission() {
		return Notification.requestPermission()
			.then(async () => {
				console.log('Notifications permission granted.');
				const token = await getToken(this.fbMessaging);
				return this.saveTokenToDb(token).toPromise();
			})
			.catch((err: FirebaseError) => {
				console.log('Notifications permission denied.');
				throw err;
			});
	}

	// // Listen for token refresh
	// private monitorTokenRefresh() {
	// 	if (this.monitorTokenRefreshSub) {
	// 		this.monitorTokenRefreshSub();
	// 	}
	// 	this.monitorTokenRefreshSub = this.fbMessaging.onTokenRefresh((refreshedToken) => {
	// 		if (refreshedToken) {
	// 			console.log('Token refreshed');
	// 			this.saveTokenToDb(refreshedToken);
	// 		}
	// 	});
	// }

	private saveTokenToDb(token: string) {
		const oldToken = this.currentToken;
		this.currentToken = token;
		let ref: string;
		return this.authSrv.user$.pipe(
			take(1),
			switchMap((user) => {
				if (user && token) {
					ref = `_users/${user.uid}/fcmTokens`;
					return zip(
						setDoc(doc(this.afFs, `${ref}/${token}`), { token, timestamp: Date.now() }),
						deleteDoc(doc(this.afFs, `${ref}/${oldToken}`))
					);
				} else {
					return of(null);
				}
			}),
			map(() => token)
		);
	}

	private deleteToken(token: string): Observable<void> {
		return this.authSrv.user$.pipe(
			take(1),
			switchMap((user) => {
				if (user && token) {
					return deleteDoc(doc(this.afFs, `_users/${user.uid}/fcmTokens/${token}`));
				} else {
					return throwError(() => 'no user or token');
				}
			})
		);
	}

	// used to show message when app is open
	private receiveMessages() {
		if (this.receiveMessagesSub) {
			this.receiveMessagesSub();
		}
		this.receiveMessagesSub = onMessage(this.fbMessaging, (payload) => {
			console.log('FCM message received. ', payload);
			this.currentMessage.next(payload);
		});
	}

	getMessages() {
		return this.currentMessage.asObservable();
	}
}
